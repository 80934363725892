import { useEffect, useState } from "react";
import checkUser, {getUsers, editUser, deleteSession} from "../api/user";
import Select from 'react-select'
import ModalAddUser from "../components/modal/ModalAddUser";

function hidden(e) {
    let elements = document.querySelectorAll(e);
    elements.forEach(element => {
        element.setAttribute("hidden", "hidden");
    });
}

function removeHidden(e) {
    let elements = document.querySelectorAll(e);
    elements.forEach(element => {
        element.removeAttribute("hidden");
    });
}

function Users() {
    const [users, setUsers] = useState([]);
    const [selectedValue, setSelectedValue] = useState(true);
    const [searchById, setSearchById] = useState();

    useEffect(() => {
        getUsers({id: searchById}).then(users => {
            checkUser();
            setUsers(users);
        });
    }, []);
      
    useEffect(() => {
        let interval = setInterval(() => {
            getUsers({id: searchById}).then(users => {
                checkUser();
                setUsers(users);
            });
        }, 10000);  
        return () => clearInterval(interval);
    }, []);

    const handleChange = (e) => {
        setSelectedValue(e.value);
    }

    const editFormUsers = (event) => {
        checkUser();
        hidden('.edit');
        removeHidden('.view');
        hidden('.view-'+event.target.id);
        removeHidden('.edit-'+event.target.id); 
    }

    const cancelUser = (event) => {
        checkUser();
        removeHidden('.view-'+event.target.id);
        hidden('.edit-'+event.target.id);
    }

    const editUserSend = (event) => {
        checkUser();
        deleteSession({ user: event.target.id })
        editUser({
            id: event.target.id,
            firstname: document.getElementById('user-'+event.target.id).querySelectorAll('.firstname')[0].value,
            lastname: document.getElementById('user-'+event.target.id).querySelectorAll('.lastname')[0].value,
            middlename: document.getElementById('user-'+event.target.id).querySelectorAll('.middlename')[0].value,
            enabled: selectedValue,
            email: document.getElementById('user-'+event.target.id).querySelectorAll('.email')[0].value,
            password: document.getElementById('user-'+event.target.id).querySelectorAll('.password')[0].value
        });
        getUsers().then(users => {
            setUsers(users);
        });
        removeHidden('.view-'+event.target.id);
        hidden('.edit-'+event.target.id);
    }

    return (
        <div className="row">
            <div className="col-lg-3">
                <h3>Список пользователей</h3>
            </div>
            <div className="col-lg-9 pull-right button">
                <div className="float-end">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModalUser">
                        <i className="fa fa-plus"/> Добавить пользователя
                    </button>
                    <ModalAddUser/>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body" id="tooltip-container">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">ФИО</th>
                                <th className="text-center">Почта</th>
                                <th className="text-center">Пароль</th>
                                <th className="text-center">Статус</th>
                                <th className="text-center">Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((e, index) => (
                                    <tr id={"user-" + e.id} key={e.id}>
                                        <td className={"text-center view view-" + e.id}>{index + 1}</td>
                                        <td className={"text-center view view-" + e.id}>{e.firstname} {e.lastname} {e.middlename}</td>
                                        <td className={"text-center view view-" + e.id}>{e.email}</td>
                                        <td className={"text-center view view-" + e.id}>Секретно</td>
                                        <td className={"text-center view enabledView view-" + e.id}>{e.enabled ? "Активен" : "Заблокирован"}</td>
                                        <td className={"text-center view view-" + e.id}><button type="button" id={e.id} onClick={editFormUsers} className="tabledit-edit-button btn btn-success"><span id={e.id} className="mdi mdi-pencil"></span></button></td>

                                        <td className={"text-center edit edit-" + e.id} hidden>{index + 1}</td>
                                        <td className={"text-center edit edit-" + e.id} hidden>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <input className="form-control firstname" placeholder={e.firstname}/>
                                                </div>
                                                <div className="col-lg-4">
                                                    <input className="form-control lastname" placeholder={e.lastname}/>
                                                </div>
                                                <div className="col-lg-4">
                                                    <input className="form-control middlename" placeholder={e.middlename}/> 
                                                </div>
                                            </div>
                                        </td>
                                        <td className={"text-center edit edit-" + e.id} hidden>
                                            <div className="col-lg-12">
                                                <input className="form-control email" placeholder={e.email}/> 
                                            </div>
                                        </td>
                                        <td className={"text-center edit edit edit-" + e.id} hidden>
                                            <div className="col-lg-12">
                                                <input className="form-control password" placeholder="Новый пароль"/> 
                                            </div>
                                        </td>
                                        <td className={"text-center edit edit-" + e.id} hidden>
                                            <Select isSearchable={true} isClearable={true} options={[{value: true, label: 'Активен'}, {value: false, label: 'Заблокирован', state: true}]} onChange={handleChange} placeholder={e.enabled ? "Активен" : "Заблокирован"}/>
                                        </td>
                                        <td className={"text-center edit edit-" + e.id} hidden>
                                            <button type="button" id={e.id} onClick={editUserSend} className="tabledit-edit-button btn btn-success"><span id={e.id} className="fa fa-check"></span></button> 
                                            <button type="button" id={e.id} onClick={cancelUser} className="tabledit-edit-button btn btn-danger"><span id={e.id} className="fa fa-times"></span></button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;
