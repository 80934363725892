import {generalPost} from "./general";
import Cookie from 'universal-cookie';
let cookie = new Cookie();

export default function checkUser() {
    if (!cookie.get('SESSION')) {
        window.location.replace('/login');
    } else {
        generalPost('api/check_login', { session: cookie.get('SESSION'), user: cookie.get('PHPUSERID') }).then(json => {
            cookie.set('ROLE', json.role, { path: '/' });
            if (!json.result) {
                window.location.replace('/login');
            }
        });
    }
}

export function login(params) {
    let api = generalPost('api/login', params);
    api.then(json => {
        if (json.result) {
            cookie.set('SESSION', json.result, { path: '/' });
            cookie.set('PHPUSERID', json.id, { path: '/' });
            cookie.set('ROLE', json.role, { path: '/' });
            cookie.set('USER', json.user, { path: '/' });
            window.location.replace('/');
        }
    });
    return api;
}

export function deleteSession(params) {
    generalPost('api/logout', params);
}

export function getUsers(params) {
    return generalPost('api/get/users', params);
}

export function editUser(params) {
    return generalPost('api/set/user', params);
}

export function addUser(params) {
    return generalPost('api/add/user', params);
}