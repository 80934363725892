import Modal from './Modal';
import {addDowntimeReasons} from '../../api/tools';

function ModalDowntimeReasons({tool}) {
    function getDetailsDowntimeReasons() {
        return {
            idTool: tool.id,
            reason: document.getElementById('reason').value,
            date: document.getElementById('date').options[document.getElementById('date').selectedIndex].value
        };
    }

    const editToolForm = (event) => {
        addDowntimeReasons(getDetailsDowntimeReasons()).then(res => {
            document.getElementById('addModalDowntimeReasons').querySelector('.btn-secondary').click();
        });
    };

    return (
        <Modal modal='addModalDowntimeReasons' size='xl' button="Сохранить" title="Добавить причину простоя" click={ editToolForm }>
        <div className="form-group">
        <label className="col-sm-3 control-label">Время простоя</label>
            <div className="col-sm-12">
                <select className="form-control select2-modal tool-type" id='date' required>
                    <option value="">Выберите время простоя</option>
                    {
                        (tool.downtimeReasons).map(e => 
                            <option 
                                key={new Date(e.startAt.date).toLocaleString("tr-TR")+"-"+new Date(e.endAt.date).toLocaleString("tr-TR")} 
                                value={new Date(e.startAt.date).toLocaleString("tr-TR")+"-"+new Date(e.endAt.date).toLocaleString("tr-TR")}>
                                    {new Date(e.startAt.date).toLocaleString("tr-TR")} - {new Date(e.endAt.date).toLocaleString("tr-TR")}
                            </option>
                        )
                    }
                </select>
            </div>
        </div>

        <div className="form-group">
            <label className="col-sm-3 control-label">Причины простоя</label>
            <div className="col-sm-12">
                <textarea className="form-control" id='reason'/>
            </div>
        </div>
        </Modal>
    );
}

export default ModalDowntimeReasons;
