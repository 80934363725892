import { useEffect, useState } from "react";
import checkUser from "../api/user";
import {getToolsDelete, getToolsDeleteSearch, refreshTool} from "../api/tools";

function Archive({role}) {
    let [tools, setTools] = useState([]);
    let [search, setSearch] = useState(null);

    function getTools(search) {
        getToolsDeleteSearch({filters: search}).then(tools => {
            checkUser();
            setTools(tools);
        });
    }

    useEffect(() => {
        getTools(search)
    }, []);
      
    useEffect(() => {
        let interval = setInterval(() => {
            getTools(search)
        }, 10000);  
        return () => clearInterval(interval);
    }, []);


    const toolsSearch = (event) => {
        if (event.target.value === "") {
            event.target.value = null;
            setSearch(null);
        }
        setSearch({name: event.target.value});
        getTools({name: event.target.value});
    };

    const clearArchive = (e) => {
        refreshTool({ id: e.target.id }).then(res => {
            getToolsDelete().then(tools => {
                setTools(tools);
            });
        });
    }

    return (
        <div className="row">
            <div className="col-lg-3">
                <h3>Оборудование в архиве</h3>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <input type="search" className="form-control" onChange={toolsSearch} placeholder="Наименование обраудования" id="top-search"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Наименование оборудование</th>
                                { role.includes('ROLE_ADMIN') ? 
                                <th className="text-center">Действия</th> : "" }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                tools.map((e, index) => (
                                    <tr key={e.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{e.name}</td>
                                        { role.includes('ROLE_ADMIN') ? 
                                        <td className="text-center">
                                            <button type="button" id={e.id} onClick={clearArchive} className="tabledit-edit-button btn btn-success"><i className="fa fa-reply" id={e.id} aria-hidden="true"></i> Восстановить</button>
                                        </td> : "" }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Archive;
