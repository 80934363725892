export default async function general(method, timeout = 1000) {
    let params = {};
    if (timeout) {
        let controller = new AbortController();
        setTimeout(() => controller.abort(), timeout);
        params = Object.assign(params, {signal: controller.signal});
    }
    const api = await fetch(`/${method}`, params);
    return api.json();
}

export async function generalPost(method, params = {}, timeout = 1000) {
    let paramsFetch = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };
    if (timeout) {
        let controller = new AbortController();
        setTimeout(() => controller.abort(), timeout);
        paramsFetch = Object.assign(paramsFetch, {signal: controller.signal});
    }
    const res = await fetch(`/${method}`, paramsFetch);
    return res.json();
}

export async function downloadFile(method, filename, timeout = 3000) {
    let params = {};
    if (timeout) {
        let controller = new AbortController();
        setTimeout(() => controller.abort(), timeout);
        params = Object.assign(params, {signal: controller.signal});
    }
    const api = await fetch(`/${method}`, params);
    if (api.status == 200) {
        const blob = await api.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}