import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HighchartsReact from 'highcharts-react-official'
import getState from "../api/getState";
import {getToolsSearch, exportStatic} from "../api/tools";
import checkUser from "../api/user";
import Highcharts from 'highcharts'
import ModalAddTool from "../components/modal/ModalAddTool";

function highchartsOption(params) {
    let color = {down: '#dc3545', pending: '#f8a306', working: '#26C413'};
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: 370
        },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 60
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '100%'
            }
        },
        series: [{
            type: 'pie',
            name: 'Статус',
            innerSize: '50%',
            data: params.map((e) => ({y: e.count, color: color[e.state], name: getState()[e.state]}))
        }]
    }
}

function Analytics({role}) {
    let [homePageInfo, setHomePageInfo] = useState({history: [], tools: [], working: ["0м", "0", 'w'], pending: ["0м", "0", 'w']});
    let [search, setSearch] = useState([]);
    let [optionHighcharts, setOptionHighcharts] = useState({title: {text: ''}});
    let colorFa = {up: 'success', down: 'danger'};

    function getTool() {
        getToolsSearch({filters: search}).then(tools => {
            checkUser();
            setHomePageInfo(tools);
            setOptionHighcharts(highchartsOption(tools.stateCount));
        });
    }

    useEffect(() => {
        getTool();
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            getTool();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

   const toolsSearch = (event) => {
        if (event.target.value === "") {
            event.target.value = null;
        }
        setSearch({name: event.target.value});
        getTool();
    };

    const refresh = () => {
        getTool();
    };

    return (
        <div className="row">

            <div className="col-lg-12 pull-right button">
                <div className="btn-toolbar float-end">
                    <a className="btn btn-success btn-custom m-1" onClick={exportStatic}>
                        <i className="fa fa-fw fa-file-excel"/> Экспорт
                    </a>
                    {
                        role.includes('ROLE_ADMIN') ? <div><a className="btn btn-primary btn-custom m-1" data-bs-toggle="modal" data-bs-target="#addModalTool"><i className="fa fa-plus"/> Добавить оборудование</a> <ModalAddTool/></div> : ""
                    }
                    
                </div>
            </div>
            
            <div className="col-lg-3">
                <div className="card highcharts-custom">
                    <div className="card-body-2">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={optionHighcharts}
                            />
                    </div>
                </div>
            </div>

            <div className="col-lg-3">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body-1" id="tooltip-container">
                            <h4 className="m-0 font-16">Время простоя</h4>
                            <h2 className="my-3 text-center" id="active-users-count">{homePageInfo.pending[0]}</h2>
                            <p className="text-muted m-0">Со вчерашнего дня: <span className="float-end">
                            <i className={homePageInfo.pending[1] !== 0 ? "fa fa-solid fa-arrow-" + homePageInfo.pending[2] + " me-1 text-" + colorFa[homePageInfo.pending[2]] : 'fa fa-solid fa-minus me-1 text-warning'}/>{Math.round(homePageInfo.pending[1])}%</span>
                        </p>
                        </div>
                    </div>  
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body-1" id="tooltip-container">
                            <h4 className="m-0 font-16">Рабочее время</h4>
                            <h2 className="my-3 text-center" id="active-users-count">{homePageInfo.working[0]}</h2>
                            <p className="text-muted m-0">Со вчерашнего дня: <span className="float-end">
                            <i className={homePageInfo.working[1] !== 0 ? "fa fa-solid fa-arrow-" + homePageInfo.working[2] + " me-1 text-" + colorFa[homePageInfo.working[2]] : 'fa fa-solid fa-minus me-1 text-warning'}/>{Math.round(homePageInfo.working[1])}%</span>
                        </p>
                        </div>
                    </div>  
                </div>
            </div>         

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title">Быстрые статусы</h4>
                        <div className="input-group">
                        <br/>
                        </div>
                        <div className="scroll-table-body-1">
                            <table className="table mb-0">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Наименование</th>
                                    <th>Статус</th>
                                    <th>Дата создания</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    homePageInfo.history.map((e, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><Link to={'/tool?id=' + e.id}>{e.name}</Link></td>
                                            <td><span className={"badge rounded-pill bg-" + e.state}>{getState()[e.state]}</span></td>
                                            <td>{e.createdAt}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <h4 className="header-title">Список оборудования</h4>
                        <div className="input-group">
                            <input type="search" className="form-control" placeholder="Наименование обраудования" onChange={toolsSearch} id="top-search"/>
                            <button className="btn" type="submit" onClick={refresh}>
                                <i className="fe-refresh-ccw"/>
                            </button>
                        </div>
                        <div className="scroll-table-body">
                            <table className="table mb-0">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Наименование</th>
                                    <th>Последние обновления</th>
                                    <th>Статус</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    homePageInfo.tools.map((e, index) => (
                                        <tr key={e.id}>
                                            <td>{index + 1}</td>
                                            <td><Link to={'/tool?id=' + e.id}>{e.name}</Link></td>
                                            <td>
                                            { e.states.map((state) => (
                                                <div className={'beat-mobile ' + state.state} key={state.id}></div>
                                            )) }
                                            </td>
                                            <td><span className={"badge rounded-pill bg-" + e.state}>{getState()[e.state]}</span></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;
