export default function EquipmentDynamics(dynamic, dynamicCurent) {
    return {
        chart: {
            type: 'spline'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'Время'
            },
            type: 'datetime',
            dateTimeLabelFormats : {
                hour: '%H:%M',
                minute: '%H:%M'
            }
        },
        yAxis: {
            title: {
                enabled: true,
                text: 'Активность оборудования (%)'
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: '%',
            xDateFormat: '%H:%M',
        },
        series: [
            {
                name: 'Сегодня',
                data: dynamicCurent
            },
            {
                name: 'Среднее',
                data: dynamic
            }
            
        ]
    }
}
