import general, {generalPost, downloadFile} from "./general";

export default function getTools() {
    return general('api/get/tools');
}

export function getToolsSearch(params) {
    return generalPost('api/get/tools', params);
}

export function getToolsDelete() {
    return general('api/get/tools/archive/show');
}

export function getToolsDeleteSearch(params) {
    return generalPost('api/get/tools/archive/show', params);
}

export function refreshTool(params) {
    return generalPost('api/refresh/tools', params);
}

export function addTool(params) {
    return generalPost('api/add/tool', params);
}

export function editTool(params) {
    return generalPost('api/edit/tool', params);
}

export function getTool(params) {
    return generalPost('api/get/tool', params);
}

export function setVibration(params) {
    return generalPost('api/set/vibration', params);
}

export function addDowntimeReasons(params) {
    return generalPost('api/add/downtime/reasons', params);
}

export function exportStatic() {
    return downloadFile('api/tools/export', 'Работа оборудования за месяц');
}