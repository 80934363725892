import Highcharts from 'highcharts'

export function getDinamycsMonth(tool, date) {
    let dinamycs = [];
    let hours = 24;
    let dateLocal = new Date(date);
    for (let i = 9; i < hours; i++) {
        let month = parseInt(Highcharts.dateFormat('%m', dateLocal)) - 1;
        let year = parseInt(Highcharts.dateFormat('%Y', dateLocal));
        if (parseInt(Highcharts.dateFormat('%m', dateLocal)) - 1 === 0) {
            month = 12;
            year = parseInt(Highcharts.dateFormat('%Y', dateLocal)) - 1;
        }
        dinamycs.push([Date.UTC(year, month, parseInt(Highcharts.dateFormat('%d', dateLocal)), i, 0), Math.round(((tool.dinamicSr[i].workState / tool.dinamicSr[i].countItem) / 60 * 60) * 100)]);
        if (i === 23) {
            i = 0;
            hours = 9;
            dateLocal.setDate(dateLocal.getDate() + 1)
        }
    }
    return dinamycs;
}

export function getDinamycsDay(tool, date) {
    let dinamycs = [];
    let hours = 24;
    let dateLocal = new Date(date);
    for (let i = 9; i < hours; i++) {
        let month = parseInt(Highcharts.dateFormat('%m', dateLocal)) - 1;
        let year = parseInt(Highcharts.dateFormat('%Y', dateLocal));
        if (parseInt(Highcharts.dateFormat('%m', dateLocal)) - 1 === 0) {
            month = 12;
            year = parseInt(Highcharts.dateFormat('%Y', dateLocal)) - 1;
        }
        if (tool.dinamicTool[i]) {
            dinamycs.push([Date.UTC(year, month, parseInt(Highcharts.dateFormat('%d', dateLocal)), i, 0), Math.round(((tool.dinamicTool[i].workState / tool.dinamicTool[i].countItem) / 60 * 60) * 100)]);
        } else {
            dinamycs.push([Date.UTC(year, month, parseInt(Highcharts.dateFormat('%d', dateLocal)), i, 0), 0]);
        }
        if (i === 23) {
            i = 0;
            hours = 9;
            dateLocal.setDate(dateLocal.getDate() + 1)
        }
    }
    return dinamycs;
}
