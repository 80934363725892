import bootstrap from 'bootstrap';

export default function Modal({children, modal, size, button, title, click}) {
    return (
        <div className="modal fade" id={modal} tabIndex="-1" aria-labelledby={modal} aria-hidden="true">
            <div className={"modal-dialog modal-" + size}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ title }</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                data-bs-dismiss="modal">Закрыть
                        </button>
                        {
                            button ? <button type="button" className="btn btn-primary" onClick={click}>{button}</button> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}