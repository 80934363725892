import Modal from './Modal';
import {editTool} from '../../api/tools';

function ModalEditTool({tool}) {
    function getDetailsTool() {
        return {
            'idTool': tool.id,
            'name': document.getElementById('editToolName').value,
            'description': document.getElementById('editToolDescription').value,
            'code': document.getElementById('editToolCode').value,
            'ip': document.getElementById('editToolIP').value,
            'port': document.getElementById('editToolPort').value,
            'id': document.getElementById('editToolRegId').value,
            'number': document.getElementById('editToolInventoryNumber').value,
            'work': document.getElementById('editToolWorkShift').value,
        };
    }

    const editToolForm = (event) => {
        editTool(getDetailsTool()).then(res => {
            document.getElementById('editModalTool').querySelector('.btn-secondary').click();
        });
    };

    return (
        <Modal modal='editModalTool' size='xl' button="Сохранить" title="Редактировать оборудование" click={ editToolForm }>
        <div className="form-group">
            <label className="col-sm-3 control-label">Наименование</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-name" placeholder={tool.name} required id='editToolName'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Описание</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-description" placeholder={tool.description} id='editToolDescription'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Код</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-code" placeholder={tool.code} id='editToolCode'/>
            </div>
        </div>2
        <div className="form-group">
            <label className="col-sm-3 control-label">IP</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-ip" pattern="\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}" placeholder={tool.ip} id='editToolIP'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">PORT</label>
            <div className="col-sm-12">
                <input type="number" className="form-control tool-controller-port" placeholder={tool.port} id='editToolPort'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Registry ID</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-registry-id" placeholder={tool.regId} id='editToolRegId'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Инвертарный номер</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-inventory-number" placeholder={tool.inventoryNumber} id='editToolInventoryNumber'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Плановые часы работы</label>
            <div className="col-sm-12">
                <input type="number" className="form-control tool-work-shift" placeholder={tool.workShift} id='editToolWorkShift'/>
            </div>
        </div>
        </Modal>
    );
}

export default ModalEditTool;
