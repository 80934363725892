import { useEffect } from 'react'
    
const Message = ({ children, color, date, messages }) => {

  useEffect(() => {
    let interval = setInterval(() => {
      if (messages.date && new Date().getTime() - messages.date.getTime() > 3000) {
        document.getElementById('alert').setAttribute('hidden', 'hidden')
      }
    }, 1000);  
    return () => clearInterval(interval);
  }, [messages]);

  const close = () => {
    document.getElementById('alert').setAttribute('hidden', 'hidden')
  }

  return (
    <div className='alert-custom' id='alert' hidden>
      <div className={"alert alert-dismissible alert-" + color} date={date}>
        <a href="#" className="btn-close" data-dismiss="alert" onClick={close} aria-label="close"></a>
        {children}
      </div>
    </div>
  )
}

export default Message;