import Modal from './Modal';
import general from '../../api/general';

function ModalAddTool(data) {
    const deleteTool = (event) => {
        general('api/delete/tool/' + data.tool.id);
        document.getElementById('deleteModalTool').querySelector('.btn-secondary').click();
    };

    return (
        <Modal modal='deleteModalTool' size='xl' button="Отправить" title="Отправить в архив" click={ deleteTool }>
            <center>Вы уверены, что хотите отправить в архив оборудование?</center>
        </Modal>
    );
}

export default ModalAddTool;