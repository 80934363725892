import Modal from './Modal';
import {addUser} from '../../api/user';

function ModalAddUser() {
    function getDetailsUser() {
        return {
            'firstname': document.getElementById('firstname').value,
            'lastname': document.getElementById('lastname').value,
            'middlename': document.getElementById('middlename').value,
            'email': document.getElementById('email').value,
            'password': document.getElementById('password').value,
        };
    }

    const addUserForm = (event) => {
        addUser(getDetailsUser()).then(
            res => {
                document.getElementById('firstname').value = '';
                document.getElementById('lastname').value = '';
                document.getElementById('middlename').value = '';
                document.getElementById('email').value = '';
                document.getElementById('password').value = '';
                document.getElementById('addModalUser').querySelector('.btn-secondary').click();
            }
        );
    };

    return (
        <Modal modal='addModalUser' size='xl' button="Создать" title="Новый пользователь" click={ addUserForm }>
        <div className="form-group">
            <label className="col-sm-3 control-label">Фамилия</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-name" placeholder='Фамилия' id='firstname'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Имя</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-description" placeholder='Имя' id='lastname'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Отчетсво</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-code" placeholder='Отчетсво' id='middlename'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Почта</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-ip" placeholder='Почта' id='email'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Пароль</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-port" placeholder='Пароль' id='password'/>
            </div>
        </div>
        </Modal>
    );
}

export default ModalAddUser;
