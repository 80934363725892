import Highcharts from 'highcharts'

function random(min, max) {
    let rand = min + Math.random() * (max + 1 - min);
    return parseFloat((rand).toFixed(2));
}

export function generateVibrationGraph(data, tool) {
    if (data.length > 100) {
        data.shift();
    }
    let utc = data['original'][data['original'].length - 1].x + 1000;
    if (tool.state == 'down') {
        data['original'].push({x: utc, y: 0});
    } else {
        data['original'].push({x: utc, y: random(-1, 1)});
    }
    data['original'] = data['original'].slice(-600);
    data['maxVibration'].push({x: utc, y: tool.maxVibration});
    data['avgVibration'].push({x: utc, y: tool.avgVibration});
    data['minVibration'].push({x: utc, y: tool.minVibration});
    data['maxVibration'] = data['maxVibration'].slice(-600);
    data['minVibration'] = data['minVibration'].slice(-600);
    data['avgVibration'] = data['avgVibration'].slice(-600);
    return data;
}


export function generateVibrationGraphTemplate(data, tool) {
    let dateCurent = new Date();
    let utc = Date.UTC(Highcharts.dateFormat('%Y', dateCurent), Highcharts.dateFormat('%m', dateCurent) - 1, Highcharts.dateFormat('%d', dateCurent), Highcharts.dateFormat('%H', dateCurent), Highcharts.dateFormat('%M', dateCurent), parseInt(Highcharts.dateFormat('%S', dateCurent)));
    for (let i = 0; i < 600; i++) {
        if (tool.state === 'down') {
            data['original'].push({x: utc + i * 1000, y: 0});
        } else {
            data['original'].push({x: utc + i * 1000, y: random(-1, 1)});
        }
        data['maxVibration'].push({x: utc + i * 1000, y: tool.maxVibration});
        data['minVibration'].push({x: utc + i * 1000, y: tool.minVibration});
        data['avgVibration'].push({x: utc + i * 1000, y: tool.avgVibration});
    }
    return data;
}

export function getVibrationGraphOptions(data) {
    return {
        chart: {
            type: 'spline',
            zoomType: 'xy'
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'Время'
            },
            type: 'datetime',
            dateTimeLabelFormats : {
                hour: '%H:%M:%S',
                minute: '%H:%M:%S'
            }
        },
        yAxis: {
            title: {
                enabled: true,
                text: 'Частота (Гц) '
            },
            zoomEnabled: false
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: '%',
            xDateFormat: '%H:%M:%S',
        },
        series: [
            {
                name: 'Максимальное значение',
                color: "#dc3545",
                data: data['maxVibration']
            },
            {
                name: 'Минимальное знаечение',
                color: "#dc3545",
                data: data['minVibration']
            },
            {
                name: 'Частота вибрации',
                data: data['original']
            },
            {
                name: 'Среднее значение',
                color: "#FFA500",
                data: data['avgVibration']
            },
        ]
    }
}