import './css/bootstrap.min.css';
import './css/app.min.css';
import './css/icons.min.css';

import AppClient from './components/AppClient'

function App() {
    return (
        <AppClient/>
    );
}

export default App;
