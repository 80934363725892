import Modal from './Modal';
import {addTool} from '../../api/tools';

function ModalAddTool() {
    function getDetailsTool() {
        return {
            'name': document.getElementById('addToolName').value,
            'description': document.getElementById('addToolDescription').value,
            'code': document.getElementById('addToolCode').value,
            'ip': document.getElementById('addToolIP').value,
            'port': document.getElementById('addToolPort').value,
            'id': document.getElementById('addToolRegId').value,
            'number': document.getElementById('addToolInventoryNumber').value,
            'work': document.getElementById('addToolWorkShift').value,
        };
    }

    const addToolForm = (event) => {
        addTool(getDetailsTool()).then(
            res => {
                document.getElementById('addToolName').value = '';
                document.getElementById('addToolDescription').value = '';
                document.getElementById('addToolCode').value = '';
                document.getElementById('addToolIP').value = '';
                document.getElementById('addToolPort').value = '';
                document.getElementById('addToolRegId').value = '';
                document.getElementById('addToolInventoryNumber').value = '';
                document.getElementById('addToolWorkShift').value = '';
                document.getElementById('addModalTool').querySelector('.btn-secondary').click();
            }
        );
        
    };

    return (
        <Modal modal='addModalTool' size='xl' button="Создать" title="Новое оборудование" click={ addToolForm }>
        <div className="form-group">
            <label className="col-sm-3 control-label">Наименование</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-name" placeholder='Наименование' required id='addToolName'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Описание</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-description" placeholder='Описание' id='addToolDescription'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Код</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-code" placeholder='Код' id='addToolCode'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">IP</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-ip" placeholder='IP' pattern="\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}" id='addToolIP'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">PORT</label>
            <div className="col-sm-12">
                <input type="number" className="form-control tool-controller-port" placeholder='PORT' id='addToolPort'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Registry ID</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-controller-registry-id" placeholder='Registry ID' id='addToolRegId'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Инвертарный номер</label>
            <div className="col-sm-12">
                <input type="text" className="form-control tool-inventory-number" placeholder='Инвертарный номер' id='addToolInventoryNumber'/>
            </div>
        </div>
        <div className="form-group">
            <label className="col-sm-3 control-label">Плановые часы работы</label>
            <div className="col-sm-12">
                <input type="number" className="form-control tool-work-shift" placeholder='Плановые часы работы' id='addToolWorkShift'/>
            </div>
        </div>
        </Modal>
    );
}

export default ModalAddTool;
