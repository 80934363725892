import Modal from './Modal';
import {setVibration} from '../../api/tools';

function ModalSettingVibration({tool}) {
    

    function getDetailsVibration() {
        return {
            'idTool': tool.id,
            'maxVibration': document.getElementById('maxVibration').value,
            'avgVibration': document.getElementById('avgVibration').value,
            'minVibration': document.getElementById('minVibration').value,
        };
    }

    const editVibrationForm = (event) => {
        setVibration(getDetailsVibration()).then(res => {
            tool.maxVibration = document.getElementById('maxVibration').value;
            tool.avgVibration = document.getElementById('avgVibration').value;
            tool.minVibration = document.getElementById('minVibration').value;
            document.getElementById('editVibration').querySelector('.btn-secondary').click();
        });
    };

    return (
        <Modal modal='editVibration' size='xl' button="Сохранить" title="Редактировать вибрацию" click={ editVibrationForm }>
            <div className="form-group">
                <label className="col-sm-3 control-label">Максимальная вибрация</label>
                <div className="col-sm-12">
                    <input type="number" className="form-control" placeholder="Максимальная вибрация" required id='maxVibration'/>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Средняя вибрация</label>
                <div className="col-sm-12">
                    <input type="number" className="form-control" placeholder="Средняя вибрация" id='avgVibration'/>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Минимальная вибрация</label>
                <div className="col-sm-12">
                    <input type="number" className="form-control" placeholder="Минимальная вибрация" id='minVibration'/>
                </div>
            </div>
        </Modal>
    );
}

export default ModalSettingVibration;
