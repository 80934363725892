import Modal from './Modal';

function ModalDowntimeReasonsList({downtimeReasons}) {
    return (
        <Modal modal='downtimeReasonsList' size='xl' title="Список простоев">
            <table className="table mb-0">
            <thead>
            <tr>
                <th className="text-center">#</th>
                <th className="text-center">Период простоя</th>
                <th className="text-center">Причина простоя</th>
            </tr>
            </thead>
            <tbody>
            {
                downtimeReasons.map((e, index) => (
                    <tr key={e.id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{new Date(e.startAt.date).toLocaleString("tr-TR").slice(0,16)+" - "+new Date(e.endAt.date).toLocaleString("tr-TR").slice(0,16)}</td>
                        <td className="text-center">{e.reason}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
        </Modal>
    );
}

export default ModalDowntimeReasonsList;
