import {
    useLocation
} from "react-router-dom";
import { useEffect, useState } from "react";
import { getTool } from "../api/tools";
import getState from "../api/getState";
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import Highcharts from 'highcharts'
import {getDinamycsDay, getDinamycsMonth} from '../components/Tool';
import {getVibrationGraphOptions, generateVibrationGraphTemplate, generateVibrationGraph} from '../components/VibrationTemplate';
import HighchartsReact from 'highcharts-react-official'
import EquipmentDynamics from "../components/EquipmentDynamics";
import ModalSettingVibration from "../components/modal/ModalSettingVibration";
import ModalDowntimeReasons from "../components/modal/ModalDowntimeReasons";
import ModalDowntimeReasonsList from "../components/modal/ModalDowntimeReasonsList";
import ModalDeleteTool from "../components/modal/ModalDeleteTool";
import ModalEditTool from "../components/modal/ModalEditTool";
import Message from "../components/Message";

function getElementsForDevice() {
    if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return 10;
    } else {
        return 30;
    }
}

function DetailsTool({role}) {
    const location = useLocation();
    var dateCurent = new Date();
    var dateYesterday = new Date();
    dateYesterday.setDate(dateYesterday.getDate() - 1);
    const [date, setDate] = useState(
        parseInt(Highcharts.dateFormat('%H', dateCurent)) + 5 >= 0 && parseInt(Highcharts.dateFormat('%H', dateCurent)) + 5 < 9
            ? dateYesterday.toISOString().slice(0,10)
            : dateCurent.toISOString().slice(0,10) );
    const [dynamic, setDynamic] = useState([]);
    const [dynamicCurent, setDynamicCurent] = useState([]);
    const [tool, setTool] = useState({downtimeReasons: [], toolStates: [], downtimeReasonsList: [], staticDay: [], staticsMonth: []});
    const [vibrationGraphOptionData, setVibrationGraphOptionData] = useState({original: [], maxVibration: [], minVibration: [], avgVibration: []});
    const [vibrationGraphOption, setVibrationGraphOption] = useState({title: {text: ''}});
    const [message, setMessage] = useState([]);

    const errorMessages = () => {
        setMessage({text: 'На данный день не найдено простоев', color: 'danger', date: new Date()});
        document.getElementById('alert').removeAttribute('hidden');
    }

    const filterView = () => {
        if (document.getElementById('filters').hasAttribute('hidden')) {
            document.getElementById('filters').removeAttribute('hidden')
        } else {
            document.getElementById('filters').setAttribute('hidden', 'hidden')
        }
    }

    const staticMonthView = () => {
        document.getElementById('title-static-table').innerHTML = "Статистика за " + tool.month;
        document.getElementById('table-static-month').removeAttribute('hidden');
        document.getElementById('table-static-day').setAttribute('hidden', 'hidden');
    }

    const modalEditSettingVibration = () => {
        if (document.getElementById('maxVibration')) {
            document.getElementById('maxVibration').value = tool.maxVibration;
        }
        if (document.getElementById('minVibration')) {
            document.getElementById('minVibration').value = tool.minVibration;
        }
        if (document.getElementById('avgVibration')) {
            document.getElementById('avgVibration').value = tool.avgVibration;
        }
    }

    const staticDayView = () => {
        document.getElementById('title-static-table').innerHTML = "Статистика за сутки";
        document.getElementById('table-static-day').removeAttribute('hidden');
        document.getElementById('table-static-month').setAttribute('hidden', 'hidden');
    }

    const setFilter = (e) => {
        setDate(e.target.value);
        e.target.setAttribute('disabled', 'disabled');
        getTool({id: 1, offset: getElementsForDevice(), dateAt: e.target.value}).then(tool => {
            setTool(tool);
            if (tool.dinamicSr !== 0 && tool.dinamicTool !== 0) {
                setDynamic(getDinamycsMonth(tool, date));
                setDynamicCurent(getDinamycsDay(tool, date));
            }
            e.target.removeAttribute('disabled');
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let toolId = query.get("id");

        getTool({id: toolId, offset: getElementsForDevice(), dateAt: date}).then(tool => {
            setVibrationGraphOptionData(generateVibrationGraphTemplate(vibrationGraphOptionData, tool));
            setVibrationGraphOption(getVibrationGraphOptions(vibrationGraphOptionData));
            setTool(tool);
            if (tool.dinamicSr !== 0 && tool.dinamicTool !== 0) {
                setDynamic(getDinamycsMonth(tool, date));
                setDynamicCurent(getDinamycsDay(tool, date));
            }

            document.getElementById('loader').setAttribute('hidden', 'hidden');
            document.getElementById('page').removeAttribute('hidden');
        });
    }, [location, date]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let toolId = query.get("id");
        
        let interval = setInterval(() => {
            if (tool.state == 'down') {
                setVibrationGraphOptionData(generateVibrationGraph(vibrationGraphOptionData, tool));
                setVibrationGraphOption(getVibrationGraphOptions(vibrationGraphOptionData));
            }
        }, 2500);
        interval = setInterval(() => {
            getTool({id: toolId, offset: getElementsForDevice(), dateAt: date}).then(tool => {
                setTool(tool);
                if (tool.dinamicSr !== 0 && tool.dinamicTool !== 0) {
                    setDynamic(getDinamycsMonth(tool, date));
                    setDynamicCurent(getDinamycsDay(tool, date));
                }
            });
        }, 30000);
        return () => clearInterval(interval);
    }, [location, date]);

    return (
        <div>
            <ModalEditTool tool={tool}/>
            <ModalDeleteTool tool = {tool} />
            <ModalSettingVibration tool={tool}/>
            <ModalDowntimeReasons tool = {tool}/>
            <ModalDowntimeReasonsList downtimeReasons = {tool.downtimeReasonsList}/>
            <div className="loader" id='loader'></div>
            <div className="row right-bar-enabled" id="page" hidden>
            <Message children={message.text} color={message.color} date={message.date} messages={message} />
            <div className="col-lg-3">
                <h3>{tool.name}</h3>
                <p>{tool.description}</p>
            </div>
            <div className="col-lg-9 pull-right button">
                <div className="float-end">
                    { role.includes('ROLE_ADMIN') ? 
                    <del>
                        <button type="button" className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#editModalTool">
                            <i className="fa fa-edit" aria-hidden="true"/>
                        </button>
                    </del> : "" }
                    <button type="button" className="btn btn-primary" onClick={filterView}>
                        <i className="fa fa-filter" aria-hidden="true"/>
                    </button>
                    {
                        tool.downtimeReasons.length ? 
                        <button type="button" className="btn btn-success ms-1" data-bs-toggle="modal" data-bs-target="#addModalDowntimeReasons">
                            <i className="fa fa-folder" aria-hidden="true"/> Добавить причину простоя
                        </button> : 
                        <button type="button" className="btn btn-success ms-1" onClick={errorMessages}>
                            <i className="fa fa-folder" aria-hidden="true"/> Добавить причину простоя
                        </button>
                    }
                    <button type="button" className="btn btn-pending m-1" data-bs-toggle="modal" data-bs-target="#downtimeReasonsList">
                        <i className="fa fa-folder" aria-hidden="true"/> Список простоев
                    </button>
                    { role.includes('ROLE_ADMIN') ? 
                    <del>
                        <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModalTool">
                            <i className="fa fa-archive"/> Отправить в архив
                        </button>
                    </del> : "" }
                </div>
            </div>

            <div className="card" id="filters" hidden>
                <div className="card-body" id="tooltip-container">
                    <div className="row">
                        <div className="col-md-2">
                            <input className="form-control" type="date" value={date} max={(new Date()).toISOString().slice(0,10)} name="date" id="createdAt" onChange={setFilter} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body" id="tooltip-container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="wrap">
                                {
                                    (tool.toolStates).map(e => 
                                        <div className={'beat ' + e.state} key={e.id}></div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <span className={"float-end badge rounded-pill state-text bg-" + tool.state}>{getState()[tool.state]}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3">
                <div className="card">
                    <div className="card-body" id="tooltip-container">
                        <h4 className="m-0 font-16">Рабочее время</h4>
                        <h2 className="my-3 text-center" id="active-users-count">{ (Math.round(tool.working / 60) || (tool.working % 60)) ? (Math.round(tool.working / 60) ? Math.round(tool.working / 60) + 'ч. ' : '') + (tool.working % 60 ? (tool.working % 60) + 'м.' : '') : "0м" }</h2>
                        <p className="text-muted m-0">Со вчерашнего дня: <span className="float-end">
                            <i className={ tool.working && tool.workingYesterDay ? tool.working < tool.workingYesterDay ? "fa fa-solid fa-arrow-down me-1 text-danger" : 'fa fa-solid fa-arrow-up me-1 text-success' : 'fa fa-solid fa-minus me-1 text-warning' }/>{ tool.workingYesterDay ? Math.round(tool.working / tool.workingYesterDay * 100) + "%" : '0%' }</span>
                        </p>
                    </div>
                </div>  
            </div>

            <div className="col-lg-3">
                <div className="card">
                    <div className="card-body" id="tooltip-container">
                        <h4 className="m-0 font-16">Время простоя</h4>
                        <h2 className="my-3 text-center" id="active-users-count">{ (Math.round(tool.simple / 60) || (tool.simple % 60)) ? (Math.round(tool.simple / 60) ? Math.round(tool.simple / 60) + 'ч. ' : '') + (tool.simple % 60 ? (tool.simple % 60) + 'м.' : '') : "0м" }</h2>
                        <p className="text-muted m-0">Со вчерашнего дня: <span className="float-end">
                            <i className={ tool.simple && tool.simpleYesterDay && Math.round(tool.simple / tool.simpleYesterDay * 100) ? tool.simple < tool.simpleYesterDay ? "fa fa-solid fa-arrow-down me-1 text-danger" : 'fa fa-solid fa-arrow-up me-1 text-success' : "fa fa-solid fa-minus me-1 text-warning" }/>{ tool.simpleYesterDay && Math.round(tool.simple / tool.simpleYesterDay * 100) ? Math.round(tool.simple / tool.simpleYesterDay * 100) + "%" : '0%'  }</span>{console.log()}
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-3">
                <div className="card">
                    <div className="card-body" id="tooltip-container">
                        <h4 className="m-0 font-16">Статус за сутки</h4>
                        <h2 className="my-3 text-center" id="active-users-count">{ ((tool.working / (tool.workShift * 60)) * 100).toFixed(2) }%</h2>
                        <i className="me-1 text-success"/>
                    </div>
                </div>
            </div>

            <div className="col-lg-3">
                <div className="card">
                    <div className="card-body" id="tooltip-container">
                        <h4 className="m-0 font-16">Плановые часы работы</h4>
                        <h2 className="my-3 text-center" id="active-users-count">{tool.workShift} ч.</h2>
                        <i className="me-1 text-success"/>
                    </div>
                </div>
            </div>

            <div className="col-xl-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h4 className="header-title" id="title-static-table">Статистика за сутки</h4>
                            <div className="btn-group mb-2">
                                <button type="button" className="btn btn-xs btn-light active" onClick={staticDayView}>Сутки</button>
                                <button type="button" className="btn btn-xs btn-light" onClick={staticMonthView}>Месяц</button>
                            </div>
                        </div>
                        <div className="table-responsive browser_users" id='table-static-day'>
                            {
                                Object.keys(tool.staticDay).map(hours =>
                                    <div key={hours} className='m-1 wr'>
                                    <OverlayTrigger
                                        trigger="focus"
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-positioned-top`}>
                                            <Popover.Header as="h3">{hours}</Popover.Header>
                                            <Popover.Body>
                                            {
                                                tool.staticDay[hours].map(itemState =>
                                                    <div key={itemState} title={itemState.reason ? getState()[itemState.state] + '\nПричина простоя: ' + itemState.reason : getState()[itemState.state]} className={'btn-inline btn p-x-1 p-y-0 btn-' + itemState.state}>
                                                        <span className="f-s-10">{itemState.createdAt}</span>
                                                    </div>
                                                )
                                            }
                                            
                                            </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <Button variant="secondary">{hours}</Button>
                                    </OverlayTrigger>
                                    </div>
                                )
                            }                                   
                        </div>
                        <div className="table-responsive browser_users" id='table-static-month' hidden>
                            <table className="table mb-0">
                                <thead className="table-light">
                                    <tr>
                                        {
                                            Object.keys(tool.staticsMonth).map(e => 
                                                <th className="border-top-0 text-center" key={e} >{e}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    {
                                        Object.keys(tool.staticsMonth).map(hours =>
                                            <td key={hours} className="text-center">
                                            <OverlayTrigger
                                                trigger="focus"
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Popover id={`popover-positioned-top`}>
                                                    <Popover.Header as="h3">{tool.staticsMonth[hours].work}</Popover.Header>
                                                    </Popover>
                                                }
                                                >
                                                {tool.staticsMonth[hours].planFact < 70 ?
                                                <Button variant="danger">{tool.staticsMonth[hours].planFact}%</Button>:
                                                <Button variant="success">{tool.staticsMonth[hours].planFact}%</Button>}
                                            </OverlayTrigger>
                                            </td>
                                        )
                                    }
                                    </tr>                             
                                </tbody>
                            </table>                                              
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body custom-highcharts-react">
                        <div className="d-flex justify-content-between">
                            <h4 className="header-title" id="title-static-table">График вибрации</h4>
                            <div className="btn-group">
                                <button type="button" className="btn btn-xs btn-light active" onClick={modalEditSettingVibration} data-bs-toggle="modal" data-bs-target="#editVibration">
                                    <i className="fa fa-cog" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={vibrationGraphOption}
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h4 className="header-title">Динамика оборудования</h4>
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={EquipmentDynamics(dynamic, dynamicCurent)}
                        />
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    );
}

export default DetailsTool;
