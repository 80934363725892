import { BrowserRouter as Router, Route , Switch, Redirect } from "react-router-dom";
import Analytics from "../route/Analytics";
import DetailsTool from  "../route/DetailsTool";
import Dashboard from "./Dashboard";
import Login from "../route/Login";
import Users from "../route/Users";
import Cookie from 'universal-cookie';
import Archive from "../route/Archive";

export default function AppClient() {
    let cookie = new Cookie();
    if (!cookie.get('ROLE')) {
        return (
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login/>
                    </Route>
                    {
                        !(cookie.get('SESSION') && cookie.get('PHPUSERID')) ? <Redirect to="/login" /> : ''
                    }
                </Switch>
            </Router>
        );
    } else {
        return (
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login/>
                    </Route>
                    {
                        !(cookie.get('SESSION') && cookie.get('PHPUSERID')) ? <Redirect to="/login" /> : ''
                    }
                    <Route exact path="/">
                        <Dashboard role={cookie.get('ROLE')} user={cookie.get('USER')}><Analytics role={cookie.get('ROLE')} /></Dashboard>
                    </Route>
                    <Route path="/tool">
                        <Dashboard role={cookie.get('ROLE')} user={cookie.get('USER')}><DetailsTool role={cookie.get('ROLE')}/></Dashboard>
                    </Route>
                    {cookie.get('ROLE').includes('ROLE_ADMIN') ?
                    <Route path="/users">
                        <Dashboard role={cookie.get('ROLE')} user={cookie.get('USER')}><Users/></Dashboard> 
                    </Route> : ""}
                    <Route path="/archive">
                        <Dashboard role={cookie.get('ROLE')} user={cookie.get('USER')}><Archive role={cookie.get('ROLE')}/></Dashboard>
                    </Route>
                </Switch>
            </Router>
        );
    }
}
