import {
    Link
} from "react-router-dom";

export default function Dashboard({children, role, user}) {
    return (
        <div>
            <div className="navbar-custom">
                <div className="container-fluid">
                    <ul className="list-unstyled topnav-menu mb-0">
                        <li className="dropdown topbar-dropdown">
                            <Link className="nav-link" to="/">
                                <i className="fa fa-home"></i>
                            </Link>
                        </li>
                        <li className="dropdown topbar-dropdown">
                            <Link className="nav-link" to="/archive" title="Управление аврхивом">
                                <i className="fa fa-archive"></i>
                            </Link>
                        </li>
                        { role.includes('ROLE_ADMIN') ? 
                        <li className="dropdown topbar-dropdown">
                            <Link className="nav-link" to="/users" title="Пользователи">
                                <i className="fa fa-users"></i>
                            </Link>
                        </li> : ""}
                    </ul>
                    
                    <ul className="list-unstyled topnav-menu float-end mb-0">
                        <li className="dropdown notification-list topbar-dropdown">
                            <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                                <img src="/noavatar.png" alt="user-image" className="rounded-circle" />
                                <span className="pro-user-name ms-1">
                                    {user} <i className="mdi mdi-chevron-down"></i> 
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                                <Link className="dropdown-item notify-item" to="/login">
                                    <i className="ri-logout-box-line noti-icon"></i>
                                    <span>Выйти</span>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="content-page">
                <div className="content">
                    {children}
                </div>
            </div>
        </div>
    );
}
