import {login, deleteSession} from "../api/user";
import Cookie from 'universal-cookie';
import { useState } from "react";
import Message from "../components/Message";


function Login() {
    let cookie = new Cookie();
    let [message, setMessage] = useState([]);

    if (cookie.get('PHPUSERID')) {
        deleteSession({ user: cookie.get('PHPUSERID') })
    }
    cookie.remove('SESSION');
    cookie.remove('PHPUSERID');

    const showPassword = () => {
        let inputPassword = document.getElementById('password');
        if (inputPassword.getAttribute('type') === 'password') {
            inputPassword.setAttribute('type', 'text')
        } else {
            inputPassword.setAttribute('type', 'password')
        }
    };

    const loginApi = (email, password) => {
        try {
            document.getElementById('emailaddress').setAttribute('disabled', 'disabled');
            document.getElementById('password').setAttribute('disabled', 'disabled');
            login({ email: email, password: password }).then(json => {
                if (!json.result) {
                    setMessage({text: 'Неверный логин или пароль', color: 'danger', date: new Date()});
                    document.getElementsByClassName('alert-custom')[0].removeAttribute('hidden');
                    document.getElementById('emailaddress').removeAttribute('disabled');
                    document.getElementById('password').removeAttribute('disabled');
                }
            })
            document.getElementById('emailaddress').removeAttribute('disabled');
            document.getElementById('password').removeAttribute('disabled');
        } catch (e) {
            setMessage({text: 'Неверный логин или пароль', color: 'danger', date: new Date()});
            document.getElementsByClassName('alert-custom')[0].removeAttribute('hidden');
            document.getElementById('emailaddress').removeAttribute('disabled');
            document.getElementById('password').removeAttribute('disabled');
        }

    }

    const loginCheck = () => {
        loginApi(document.getElementById('emailaddress').value, document.getElementById('password').value);
    };

    const loginCheckKey = (event) => {
        if (event.keyCode === 13) {
            loginApi(document.getElementById('emailaddress').value, document.getElementById('password').value);
        }
    };

    const loginGuest = () => {
        loginApi('guest@npo-at.com', 'guest');
    };

    return (
        <div className="account-pages mt-5 mb-5">
            {message ? <Message children={message.text} color={message.color} date={message.date} messages={message} /> : ""}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="mb-2">
                                    <label className="form-label">Почта</label>
                                    <input className="form-control" type="email" id="emailaddress" onKeyDown={loginCheckKey} required="" placeholder="Введите почту"/>
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">Пароль</label>
                                    <div className="input-group input-group-merge">
                                        <input type="password" id="password" className="form-control" onKeyDown={loginCheckKey} placeholder="Введите пароль"/>
                                        
                                        <div className="input-group-text" onClick={showPassword} data-password="false">
                                            <span className="password-eye"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-grid mb-0 text-center">
                                    <button className="btn btn-primary" type="button" onClick={loginCheck}> Вход </button>
                                    <button className="btn btn-success mt-1" type="button" onClick={loginGuest}> Демо режим </button>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
